import { Component, inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { PrefixSubscriptionPipe, TranslatePipe } from '../../../../utils/pipes';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LegacyLinkInnerDirective } from '../../../../utils/directives';
import { DefaultService } from '../../../../utils/api';
import { DatePipe } from '@angular/common';
import { HelpersService } from '../../../services/helpers.service';

type GlobalNotification = 'licenserenewed';

@Component({
    selector: 'app-global-notifications',
    imports: [TranslatePipe, PrefixSubscriptionPipe, RouterModule, LegacyLinkInnerDirective, DatePipe],
    templateUrl: './global-notifications.component.html',
    styleUrl: './global-notifications.component.scss'
})
export class GlobalNotificationsComponent {
    defaultService = inject(DefaultService);
    authService = inject(AuthService);
    helpersService = inject(HelpersService);
    user = this.authService.user;

    route = inject(ActivatedRoute);

    globalNotification?: GlobalNotification;

    ngOnInit() {
        const notificationQueryParam: 'licenserenewed' = this.route.snapshot.queryParams.appglobalnotification;
        if (notificationQueryParam) {
            this.globalNotification = notificationQueryParam;
            this.helpersService.removeParam('appglobalnotification');
        }
    }

    hideBillingNotification() {
        this.defaultService
            .billingHideNotification({
                severity: this.user().notification.severity,
                targetSubscriptionId: this.user().subscriptionId
            })
            .subscribe();
        this.user.update((user) => ({ ...user, notification: null }));
    }
}
