<div class="app-navigation">
    <div class="container">
        <nav class="left-wrap" [class.nav--compact]="user().menuItems?.length >= 7">
            <!-- nav items left -->
            @for (item of user().menuItems; track $index) {
                <a class="nav-item" [routerLink]="item.url | prefixSubscription" routerLinkActive="active" [routerLinkActiveOptions]="{exact: !item.url ? true : false}">
                    <div class="legacy-link-inner" legacyLinkInner [legacy]="!angularNavCodes.includes(item.code)">
                        <i class="icon icon-nav icon-nav--{{item.code}}"></i>
                        <span class="title">{{item.descr}}</span>
                        @if (item.amount !== undefined) { <span class="amount">{{item.amount}}</span> }
                    </div>
                </a>
            }
        </nav>
        <div class="right-wrap">
            @if (user().type !== 'staff') {
                <button class="notifications" [ngbPopover]="notificationsPopover" [popoverClass]="'popover--notifications'" [placement]="'bottom auto'" [container]="'body'" [autoClose]="'outside'" (shown)="onOpenNotifications()" (hidden)="onCloseNotifications()">
                    <i class="icon icon-notifications"></i>
                    @if (unreadNotifications()?.length && !currentNotificationsOpened) { <i class="unread"></i> }
                </button>
            }
            <div class="profile" #profilePopoverRef="ngbPopover" [ngbPopover]="profilePopover" [popoverClass]="'popover--profile'" [placement]="'bottom-right'" [container]="'body'" [autoClose]="'outside'" [animation]="false">
                <app-avatar [size]="32" [fontSize]="14" [type]="user().type" [classes]="['app-avatar--light']" [firstname]="user().firstname" [lastname]="user().lastname" [image]="user().avatar" />
                <div class="user-info">
                    <div class="title">{{user().fullname}}</div>
                    @if (user().type !== 'supervisor' || user().trainingDescr || true) { 
                        <div class="descr">
                            <ng-container [ngTemplateOutlet]="userDescr" [ngTemplateOutletContext]="{user: user()}" />
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>

<!-- notifications popover -->
<ng-template #notificationsPopover>
    <div class="title">{{'navigation_notifications' | translate}}</div>
    @if (!notifications()?.length) {
        <div class="empty-state">{{'navigation_notifications_emptystate' | translate}}</div>
    } @else {
        <div class="notifications-list">
            @for (notification of notifications(); track $index) {
                <a class="notification-item" [href]="[notification.link]">
                    <div class="left">
                        <app-avatar [size]="36" [fontSize]="14" [type]="notification.actor.role" [fullname]="notification.actor.name" [image]="notification.actor.avatar" />
                    </div>
                    <div class="right">
                        <div class="name-wrap">
                            <div class="name">{{notification.actor.name}}</div>
                            <div class="ts">{{notification.time_ago}}</div>
                        </div>
                        <div class="descr-wrap">
                            <div class="descr">{{notification.title}}</div>
                            @if (!notification.read) { <i class="unread"></i> }
                        </div>
                    </div>
                </a>
            }
        </div>
    }
</ng-template>

<!-- profile popover -->
<ng-template #profilePopover>
    <button class="close" (click)="profilePopoverRef.close()"><i></i></button>
    <div class="profile-wrap">
        <app-avatar [size]="30" [fontSize]="14" [type]="user().type" [firstname]="user().firstname" [lastname]="user().lastname" [image]="user().avatar" />
        <div class="user-info">
            <div class="title">{{user().fullname}}</div>
            <div class="descr"><ng-container [ngTemplateOutlet]="userDescr" [ngTemplateOutletContext]="{user: user()}" /></div>
        </div>
    </div>
    <!-- conditional research -->
    @if (user().researchTypes?.length) {
        <div class="research-wrap">
            <div class="popover-title line">{{'profilepopover_research_title' | translate}}</div>
            @for (item of user().researchTypes; track $index) { 
                <a [routerLink]="[('research/' + item.type) | prefixSubscription]" class="popover-link line">
                    <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{item.title}}</div>
                </a> 
            }
        </div>
    }
    <!-- conditional course / surveys -->
    @if (user().hasSurveys) {
        <div class="course-wrap">
            <div class="popover-title line">{{'profilepopover_course_title' | translate}}</div>
            <a [routerLink]="['surveys' | prefixSubscription]" class="popover-link line">
                <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'profilepopover_link_surveys' | translate}}</div>
            </a>
        </div>
    }
    <!-- conditional downloads -->
    @if (user().downloadTypes?.length) {
        <div class="downloads-wrap">
            <div class="popover-title line">{{'profilepopover_downloads_title' | translate}}</div>
            @for (item of user().downloadTypes; track $index) { 
                <a download [routerLink]="[item.url | prefixSubscription]" class="popover-link line">
                    <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{item.descr}}</div>
                </a> 
            }
        </div>
    }
    <!-- @todo @legacy supervisors now also need privacy page? -->
    <div class="settings-wrap">
        <div class="popover-title line">{{'profilepopover_settings_title' | translate}}</div>
        <a [routerLink]="['settings/account' | prefixSubscription]" class="popover-link line">
            <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'profilepopover_link_my_credentials' | translate}}</div>
        </a>
        <a [routerLink]="['settings/subscriptions' | prefixSubscription]" class="popover-link line">
            <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'profilepopover_link_my_accounts' | translate}}</div>
        </a>
        <!-- @if (user().hasLocations) { 
            <a [routerLink]="['settings/locations' | prefixSubscription]" class="popover-link line">
                <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'profilepopover_link_locations' | translate}}</div>
            </a> 
        } -->
        @if (user().type === 'assistant') { 
            <a [routerLink]="['settings/privacy' | prefixSubscription]" class="popover-link line">
                <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'profilepopover_link_privacy' | translate}}</div>
            </a> 
        }
        @if (user().hasBilling) { 
            <a [routerLink]="['settings/billing' | prefixSubscription]" class="popover-link line">
                <div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'profilepopover_link_billing' | translate}}</div>
            </a> 
        }
    </div>
    <!-- accounts -->
    <div class="accounts-wrap" [attr.inert]="switchingToAccountId || null">
        <div class="popover-title">{{'profilepopover_accounts_title' | translate}}</div>
        <div class="accounts-list">
            @for (subscription of user().subscriptions; track $index) {
                <a [href]="subscription.role + '/' + subscription.id" 
                    (click)="switchAccount($event, subscription.id)"
                    class="account-button account-button--{{subscription.role}}"
                    [class.active]="(switchingToAccountId === subscription.id) || (!switchingToAccountId && subscription.id === user().subscriptionId)"
                    [class.saving]="switchingToAccountId && switchingToAccountId !== subscription.id">
                    <i class="radio"></i>
                    <div class="info-wrap">
                        <!-- role / status -->
                        <div class="title">
                            @switch (subscription.role) {
                                @case ('assistant') { {{subscription.trainingDescr}} }
                                @case ('supervisor') { {{user().jobTitle || ('misc_professional_account' | translate)}} }
                                @case ('pro') { {{('misc_pro_account' | translate)}} }
                                @case ('staff') { {{subscription.trainingDescr }} }
                                @case ('admin') { {{ !subscription.organisationDescr ? 'Super admin' : 'Admin' }} }
                            }
                            @switch (subscription.status) {
                                <!-- @case ('active') { <div class="type-stamp type-stamp--green">{{'status_active' | translate}}</div> } -->
                                @case ('expired') { <div class="type-stamp type-stamp--orange">{{'status_expired' | translate}}</div> }
                                @case ('archived') { <div class="type-stamp type-stamp--disabled">{{'status_archived' | translate}}</div> }
                            }
                        </div>
                        <!-- descr (organisation / - / organisation) -->
                        @if ((subscription.role === 'assistant' || subscription.role === 'staff' || subscription.role === 'admin') && subscription.organisationDescr) { <div class="descr ellipsis"> {{subscription.organisationDescr}}</div> }
                    </div>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16"
                        style="enable-background:new 0 0 16 16;" xml:space="preserve">
                        <g id="surface5886">
                            <path id="Vector" d="M8,0C3.6,0,0,3.6,0,8c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8C16,3.6,12.4,0,8,0z M8,1.2
                                    c3.8,0,6.8,3,6.8,6.8c0,3.8-3,6.8-6.8,6.8c-3.8,0-6.8-3-6.8-6.8C1.2,4.2,4.2,1.2,8,1.2z M8,3.2C7.2,3.2,6.6,3.5,6.2,4
                                    C5.8,4.4,5.6,5,5.6,5.6s0.2,1.2,0.6,1.6C6.6,7.7,7.2,8,8,8s1.4-0.3,1.8-0.8c0.4-0.5,0.6-1.1,0.6-1.6S10.2,4.4,9.8,4
                                    C9.4,3.5,8.7,3.2,8,3.2z M8,4.4c0.4,0,0.7,0.1,0.9,0.4C9.1,5,9.2,5.3,9.2,5.6c0,0.3-0.1,0.6-0.3,0.8C8.7,6.7,8.4,6.8,8,6.8
                                    c-0.4,0-0.7-0.1-0.9-0.4C6.9,6.2,6.8,5.9,6.8,5.6c0-0.3,0.1-0.6,0.3-0.8C7.3,4.5,7.5,4.4,8,4.4z M5.3,8.8C4.6,8.8,4,9.4,4,10.1v0.4
                                    c0,0.8,0.5,1.5,1.2,1.9c0.7,0.5,1.7,0.7,2.8,0.7c1.1,0,2.1-0.3,2.8-0.7c0.7-0.5,1.2-1.1,1.2-1.9v-0.4c0-0.7-0.6-1.3-1.3-1.3H5.3z
                                    M5.3,10h5.4c0.1,0,0.1,0,0.1,0.1v0.4c0,0.3-0.2,0.6-0.7,0.9C9.7,11.8,8.9,12,8,12s-1.7-0.2-2.1-0.5c-0.5-0.3-0.7-0.6-0.7-0.9v-0.4
                                    C5.2,10,5.2,10,5.3,10z" />
                        </g>
                    </svg>
                </a>
            }
        </div>
    </div>
    <!-- logout -->
    <div class="logout-wrap">
        <button (click)="authService.logout()" class="popover-link">
            <div class="legacy-link-inner">{{'profilepopover_link_logout' | translate}} <i class="icon icon-logout"></i></div>
        </button>
    </div>
</ng-template>

<!-- @link user descr -->
<ng-template #userDescr let-user="user">
    @switch (user.type) {
        @case ('assistant') { {{user.trainingDescr}} }
        @case ('supervisor') { {{user.jobTitle || ('misc_professional_account' | translate)}} }
        @case ('staff') { {{user.trainingDescr || ('misc_collaborator' | translate)}} }
    }
</ng-template>