import { Inject, Injectable, inject } from '@angular/core';
import { ErrorService } from './error.service';
import { ServerConfig } from '../models/config.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    errorService = inject(ErrorService);
    authService = inject(AuthService);

    initialize(config: ServerConfig): Promise<void> {
        return new Promise<void>((resolve) => {
            if (config.instrumentationkey) this.errorService.initMonitoring(config.instrumentationkey);
            resolve();
        });
    }
}
