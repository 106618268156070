import { Component, HostListener, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { RoutingService } from './shared/services/routing.service';
import { ConfigService } from './shared/services/config.service';
import { AppService } from './shared/services/app.service';
import { ServerConfig } from './shared/models/config.model';
import { AuthService } from './shared/services/auth.service';
import { User } from './utils/api';
import { TopComponent } from './shared/components/layout/top/top.component';
import { NavigationComponent } from './shared/components/layout/navigation/navigation.component';
import { FooterComponent } from './shared/components/layout/footer/footer.component';
import { getCookie } from './shared/services/helpers.service';
import { AdminNavigationComponent } from './shared/components/layout/admin-navigation/admin-navigation.component';
import { GlobalNotificationsComponent } from './shared/components/layout/global-notifications/global-notifications.component';

@Component({
    selector: 'app-root',
    imports: [
        CommonModule,
        RouterOutlet,
        TopComponent,
        NavigationComponent,
        FooterComponent,
        AdminNavigationComponent,
        GlobalNotificationsComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    configService = inject(ConfigService);
    appService = inject(AppService);
    routingService = inject(RoutingService);
    authService = inject(AuthService);
    route = inject(ActivatedRoute);

    ready = false;
    reloading = false;
    version = '';

    constructor(@Inject('config') private config: ServerConfig, @Inject('user') private user: User) {}

    async ngOnInit(): Promise<void> {
        this.configService.serverConfig.set(this.config);
        this.appService.init();
        this.authService.user.set({
            ...this.user,
            fullname: this.user?.fullname || `${this.user?.firstname ?? ''} ${this.user?.lastname ?? ''}`.trim()
        });
        this.authService.init();
        this.version = this.appService.getVersion();
        await this.routingService.init();
        this.ready = true;
        if (this.user?.type) this.handleCookieSecurity();
    }

    handleCookieSecurity() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                that.reloading = true;
                window.location.reload();
            }
        });
        window.addEventListener('focus', () => {
            const sub = getCookie('sub');
            if (sub) {
                const subscriptionType = sub.split('/')?.[0];
                const subscriptionId = parseInt(sub.split('/')?.[1]);
                if (
                    (that.user.type && subscriptionType && that.user.type !== subscriptionType) ||
                    (that.user.subscriptionId && subscriptionId && that.user.subscriptionId !== subscriptionId)
                ) {
                    if (
                        ['assistant', 'supervisor', 'staff', 'admin'].includes(subscriptionType) &&
                        Number.isInteger(subscriptionId)
                    ) {
                        window.location.href = `${subscriptionType}/${subscriptionId}`;
                    }
                }
            }
        });
    }
}
