import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
    ApplicationConfig,
    LOCALE_ID,
    importProvidersFrom,
    mergeApplicationConfig,
    inject,
    provideAppInitializer
} from '@angular/core';
import { AppComponent } from './app/app.component';
import { RouteWithConfig, ServerConfig } from './app/shared/models/config.model';
import { InitService } from './app/shared/services/init.service';
import { appConfig } from './app/app.config';
import { ApiModule, Configuration, User } from './app/utils/api';
import { LanguageService } from './app/shared/services/language.service';
import { Language } from './app/shared/models/common.model';

const isAuth =
    window.location.pathname.includes('/auth/') ||
    window.location.pathname.includes('/login') ||
    window.location.pathname.includes('/lost-password');
const userInfoPromise = !isAuth
    ? fetch('/v1/users/userinfo', { credentials: 'include' })
          .then((res) => {
              if (res.ok) return res.json();
              handleUnauthRedirectOnPrivateRoutes();
              return res;
          })
          .then((user: User) => user)
    : (Promise.resolve() as any);

const configPromise = fetch('/v1/config', { credentials: 'include' })
    .then((res) => {
        if (res.ok) return res.json();
        return res;
    })
    .then((config: ServerConfig) => config);

Promise.all([userInfoPromise, configPromise])
    .then(async ([userInfoResponse, configResponse]) => {
        // public
        if (!(userInfoResponse as User)?.id) {
            import('./app/shared/routes/public.routes').then((res) => {
                fullSend(res.publicRoutes(null, configResponse, true), configResponse, null);
            });
            return;
        }
        // private
        switch (userInfoResponse.type) {
            case 'assistant':
                import('./app/modules/assistant/assistant-routes').then((res) => {
                    fullSend(res.routes(userInfoResponse, configResponse), configResponse, userInfoResponse);
                });
                break;
            case 'supervisor':
                import('./app/modules/supervisor/supervisor-routes').then((res) => {
                    fullSend(res.routes(userInfoResponse, configResponse), configResponse, userInfoResponse);
                });
                break;
            case 'staff':
                import('./app/modules/staff/staff-routes').then((res) => {
                    fullSend(res.routes(userInfoResponse, configResponse), configResponse, userInfoResponse);
                });
                break;
            case 'admin':
                import('./app/modules/admin/admin-routes').then((res) => {
                    fullSend(res.routes(userInfoResponse, configResponse), configResponse, userInfoResponse);
                });
                break;
        }
    })
    .catch((error) => console.error(error));

const handleUnauthRedirectOnPrivateRoutes = () => {
    const userTypes = ['assistant', 'supervisor', 'staff', 'admin'];
    for (const userType of userTypes) {
        if (window.location.pathname.includes(`/${userType}`)) {
            let to = `${window.location.pathname.replace(/^\/+/g, '')}`;
            if (to) {
                to = `?backTo=${to}`;
            } else to = '';
            !window.location.pathname.includes('/login') ? (window.location.href = `login${to}`) : null;
        }
    }
};

const fullSend = (routes: RouteWithConfig[], configResponse: ServerConfig, userInfoResponse?: User) => {
    const browserConfig: ApplicationConfig = {
        providers: [
            // routes
            provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
            // custom
            { provide: 'config', useValue: configResponse },
            { provide: 'user', useValue: userInfoResponse },
            importProvidersFrom(
                ApiModule.forRoot(() => {
                    return new Configuration({ basePath: <string>configResponse.baseUrl, withCredentials: true });
                })
            ),
            provideAppInitializer(() => {
                const initializerFn = ((initService: InitService) => {
                    return () => {
                        return initService.initialize(configResponse);
                    };
                })(inject(InitService));
                return initializerFn();
            }),
            {
                provide: LOCALE_ID,
                deps: [LanguageService],
                useFactory: (languageService: LanguageService) =>
                    languageService.getLocale(<Language>userInfoResponse?.language || languageService.getLanguage())
            }
        ]
    };

    // bootstrap the application 🚀
    bootstrapApplication(AppComponent, mergeApplicationConfig(appConfig, browserConfig)).catch((err) =>
        console.error(err)
    );
};
